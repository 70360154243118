@import "src/colorVariable.scss";
#overview {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);

    .save-btn {
      background-color: green;
      width: auto;
      display: inline-block;
      padding: 6px 23px;
      color: white;
      font-weight: 300;
      font-size: 14px;
      border-radius: 30px;
      position: fixed;
      right: 64px;

      top: 46px;
      z-index: 9;
      cursor: pointer;
    }
    .front-page-content {
      background-color: rgb(240, 240, 240);
      margin: 20px;
      position: relative;
      .info {
        position: fixed;
        flex-wrap: wrap;
        top: 84px;
        width: calc(100% - 256px);
        background-color: white;
        height: 100%;
        overflow: auto;
        .addBooking {
          color: $color-link;
        }
        .close,
        .addBooking,
        .save {
          padding: 6px 23px;
          cursor: pointer;
          font-size: unset;
          display: flex;
          align-items: center;
          p {
            margin: 0;
          }
          .fa-times {
            font-size: 24px;
            margin-left: 10px;
          }
        }
        .save {
          color: white;
          background-color: green;
        }
      }
      .welcome-editor {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        padding: 20px;
      }
      .headline {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0px;
        background-color: white;
        width: calc(100% - 257px);
        z-index: 4;
      }
      .calendar {
        margin-top: 120px;
        display: flex;
        justify-content: end;
        min-width: 840px;
        overflow: auto;
        .houses {
          padding-top: 128px;
          max-width: 300px;
          .each-week {
            padding-bottom: 38px;
            > div {
              height: 38px;
              display: flex;
              align-items: center;
              margin: 10px 0;
              color: white;
              border-right: solid 2px white;
              p {
                padding: 0 20px;
                width: max-content;
                margin: 0;
              }
            }
          }
        }
        .react-calendar__month-view__days {
          align-items: start;
          background-color: rgba(0, 0, 0, 0.04);
          button {
            abbr {
              background-color: white;
              width: 100%;
              display: block;
              padding: 10px;
              height: 100%;
            }
          }
        }
        .react-calendar__tile {
          padding: 0px 0 !important;
          overflow: unset !important;
        }
        .react-calendar__tile:nth-child(7n + 7) {
          .some-booked {
            &.lastDate {
              // width: 100%;
              // border-radius: 0;
            }
          }
        }
        .react-calendar {
          width: auto;
          border: 0;

          abbr {
            color: black;
          }
          .small-calendar-text {
            font-size: 0.8em;
            color: limegreen;
          }
          .available {
            background-color: rgba(0, 0, 0, 0.07);
            color: rgba(0, 0, 0, 0.5) !important;
          }
          .some-booked {
            color: white;
            position: relative;
            padding: 10px;
            display: flex;
            max-height: 38px;
            align-items: center;
            justify-content: center;
            min-height: 38px;
            // margin: 1px 0.5px;
            margin: 10px 0;
            overflow: hidden;
            &.firstDate {
              // background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.6) 50%, transparent 50%);
              border-top-left-radius: 40px;
              border-bottom-left-radius: 40px;
              margin-left: auto;
              margin-right: 0;
              // width: calc(70%);
            }
            &.lastDate {
              // background: linear-gradient(to bottom right, transparent 50%, rgba(255, 255, 255, 0.6) 50%);
              border-top-right-radius: 40px;
              border-bottom-right-radius: 40px;
              // width: calc(130%);
            }
            p {
              position: absolute;
              right: 0px;
              top: 1px;
              color: white;
              border-radius: 20px;
              font-size: 10px;
              padding: 0 10px;
              text-transform: lowercase;
            }
            // &.lastDate.firstDate {
            //   width: 130%;
            //   margin-left: unset;
            //   margin-right: unset;

            // }
            > div {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .sold-out {
            color: red;
          }
          .react-calendar__tile--active {
            background-color: unset;
            filter: brightness(0.8);
          }
        }
      }
      table {
        border-collapse: collapse;
        font-size: 11px;
      }

      table td,
      table th {
        border: 1px solid #ddd;
        padding: 8px;
      }
      table td:last-child {
        padding: 0;
      }
      .wrap-table-header {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        > div {
          display: flex;
        }
      }
      .wrap-table {
        overflow: auto;
        width: 100%;
      }
      table {
        table-layout: fixed;
        min-width: 1000px;
        margin: auto;
        .disabled {
          opacity: 0.6;
          pointer-events: none;
        }
        td {
          word-wrap: break-word;
          text-align: center;
          input,
          .select,
          textarea {
            height: unset;
            font-size: unset;
            max-height: unset;
            min-height: unset;
            border-radius: unset;
          }
          .react-bootstrap-daterangepicker-container {
            cursor: pointer;
            padding: 0 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background-color: white;
          }

          .action-btn {
            display: inline-block;
            text-align: center;
            width: 50%;
            height: 100%;
            vertical-align: top;
            padding: 5px;

            cursor: pointer;
            font-size: 12px;
            p {
              margin: 0;
            }
          }
          .action-btn:nth-child(1) {
            background-color: red;
            color: white;
            width: 100%;
          }
          // .action-btn:nth-child(2) {
          //   background-color: $color-first;
          //   color: white;
          // }

          // .action-btn:nth-child(1) {
          //   width: 100%;
          //   background-color: green;
          //   color: white;
          // }
          .action-btn:hover {
            opacity: 0.8;
          }
          input {
            height: auto;
            width: 100%;
            text-align: center;
          }
        }
      }
      table tr:nth-child(even) {
        background-color: #f7f7f7;
      }

      table {
        th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: rgba(97, 151, 222, 1);
          color: white;
          position: relative;
          cursor: pointer;
          svg {
            position: absolute;
            right: 3px;
            top: 3px;
          }
          .fa-filter {
            opacity: 0.2;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #overview {
    margin-left: 50px;
    .wrap-content {
      width: calc(100% - 50px);

      .front-page-content {
        background-color: transparent;
        margin: 0;
        .headline {
          position: fixed;
          top: 0;
          z-index: 2;
          background-color: white;
          width: calc(100% - 40px);
        }
        .info {
          width: calc(100% - 50px);
          .close {
            float: unset;
            // position: fixed;
          }
        }
        table {
          margin-top: 60px;
        }
        .calendar {
          margin-top: 80px;
        }

        //       .calendar {
        //         margin-left: -18px;
        //         position: fixed;
        //         width: calc(100% - 54px);
        //         margin-top: 80px;
        //         .react-calendar,
        //         .react-calendar *,
        //         .react-calendar *:before,
        //         .react-calendar *:after {
        //           border: none;
        //         }
        //         .react-calendar {
        //           font-size: 0.6em;
        //           height: 290px;
        //           abbr {
        //             font-size: 1.8em;
        //           }
        //           .small-calendar-text {
        //             font-size: 1em;
        //           }
        //           .react-calendar__navigation {
        //             font-size: 1.8em;
        //           }
        //         }
        //       }
        //       .info{
        //         .close {
        //           float: unset;
        //         }
        //         table {
        //           margin-left: -18px;
        //           tr {
        //             td {
        //               .action-btn {
        //                 p {
        //                   font-size: 2.1vw;
        //                 }
        //               }
        //             }
        //       }
        //         }
        //       }
      }
    }
  }
}
