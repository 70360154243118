@import "src/colorVariable.scss";
.css-yrspe, .css-1rbq2qy {
  z-index: 4 !important;
}
#cabins {
  .daterangepicker {
    right: 12vw;
    top: 63vw;
  }
  .about-content1 {
    .sold-out {
      background-color: #dcdcdc;;
    }
  }
  .cabin-gallery {
    background-color: #eae9e9;
    padding-bottom: 6.2vw;
    padding-top: 1px;
    .wrap-cabin-gallery {
      margin-top: 6.2vw;
      margin-left: 6.2vw;
      vertical-align: top;
      width: calc(100% - 12.4vw);
      h1 {
        margin: 0;
        padding-bottom: 2vw;
        font-weight: 900;
        font-size: 4.8vw;
      }
      .cabin-image {
        width: 25%;
        vertical-align: top;
        display: inline-block;
        img {
          cursor: pointer;
          object-fit: cover;
          width: 100%;
          height: 20vw;
          padding: 10px;
        }
        img:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  #cabins {
    .about-content1 {
    } 
    .cabin-gallery {
      margin: auto;
      padding-bottom: 60px !important;
      .wrap-cabin-gallery {
        margin: auto !important;
        margin-top: 60px !important;
        width: 1040px !important;
        h1 {
          padding-bottom: 22px;
          font-size: 60px;
        }
        .cabin-image {
          img {
            height: 260px !important;
          }
        } 
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #cabins {
    .cabin-gallery {
      .wrap-cabin-gallery {
        h1 {
          font-size: 9vw;
          line-height: 10vw;
          letter-spacing: 0.5vw;
        }
        p {
          font-size: 3.5vw;
          line-height: 5.5vw;
        }
        .cabin-image {
          margin: auto;
          width: 50%;
          img {
            height: 40vw;
          }
        }
      }
    }
  }
  .daterangepicker {
    left: 5vw;
    width: calc(100% - 10vw) !important;
    .drp-calendar {
      max-width: 88vw !important;
    }
  }
}
