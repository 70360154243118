@import "src/colorVariable.scss";
#packageAvailability {
  .headline {
    width: 1200px;
    margin: auto;
    margin-top: 100px;
    max-width: 90%;
    margin-bottom: 60px;
    h1 {
      font-weight: 900;
      color: white;
      font-size: 60px;
    }
  }
  .filter {
    margin: auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
      align-items: unset;
    }

    .input {
      width: calc(80% - 20px);
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1200px) {
        margin: auto;
        width: calc(100% - 10vw);
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: unset;
      }
      > div {
        width: 100%;
        @media screen and (max-width: 768px) {
          margin: 10px 0;
          width: 50%;
        }
        label {
          color: #fab84c;
          margin: 0;
        }
        > div {
          width: 100%;
          position: relative;
          input {
            width: calc(100% - 8px);
            padding-left: 26%;
            height: 60px;
          }
          .fa-calendar-alt {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            font-size: 30px;
          }
          .react-bootstrap-daterangepicker-container {
            display: unset !important;
          }
          .plus-minus {
            position: absolute;
            right: 10px;

            display: flex;

            justify-content: space-between;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            align-items: center;

            > div {
              background-color: #dcdcdc;
              padding: 0 8px;
              height: calc(100% - 2px);
              display: flex;
              align-items: center;
              color: white;
              cursor: pointer;
            }
            .remove-people {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              margin-left: 1px;
            }
          }
        }
      }
    }
    .checkAvailability {
      width: 20%;
      @media screen and (max-width: 1200px) {
        margin: auto;
        margin-top: 20px;
        width: calc(100% - 10vw);
      }
      .all-btn {
        height: 60px;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          width: unset;
          height: unset;
          font-size: 18px;
        }
      }
    }
  }
  .date-type {
    margin: auto;
    width: 1200px;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 10vw);
    }
    flex-wrap: wrap;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    .clear {
      color: $color-link;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        // width: calc(100% - 10vw);
        margin-top: 20px;
      }
    }
    .date-button {
      cursor: pointer;
      display: inline-block;
      border-radius: 4px;
      background-color: white;
      color: $color-first;
      margin-right: 15px;
      @media screen and (max-width: 768px) {
        // width: calc(100% - 10vw);

        margin-top: 20px;
      }
      div {
        padding: 10px 30px;
      }
    }
    .date-button-selected {
      background-color: green;
      color: white;
    }
  }
  .table {
    height: 10vw;
    margin: auto;
    width: calc(100% - 10vw);
    margin-left: 5vw;
    color: $color-second;

    div {
      .input-headline {
        vertical-align: bottom;
        padding-bottom: 1.2vw;
        font-size: 1.3vw;
        border: none;
        div {
          display: inline-block;
          width: 25%;
        }
      }
      .input {
        vertical-align: bottom;
        border: none;
        position: relative;
        justify-content: space-around;
        display: flex;
        align-items: flex-end;
        .th {
          position: relative;

          .occupied {
            left: 45%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            position: absolute;
            color: red;
            font-size: 0.7vw;
          }

          .fa-calendar-alt,
          .fa-user-friends,
          .fa-sort-down {
            position: relative;
            z-index: 3;
            pointer-events: none;
            color: black;
            font-size: 2.2vw;
            left: 1.5vw;
          }
          .add-people,
          .remove-people {
            line-height: 5.3vw;
            height: 100%;
            width: 2.5vw;
            text-align: center;
            position: absolute;
            z-index: 1;
            background-color: #dcdcdc;
            right: 0;
            cursor: pointer;
            .fa-plus,
            .fa-minus {
              font-size: 1.3vw;
              color: white;
            }
          }
          .add-people {
            border-top-right-radius: 0.3vw;
            border-bottom-right-radius: 0.3vw;
            right: 0.1vw;
          }
          .remove-people {
            right: 2.7vw;
          }
          .missing {
            color: red !important;
          }
          .fa-plus,
          .fa-minus {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            cursor: pointer;
            pointer-events: fill;
            font-size: 1.4vw;
            left: auto;
            top: 20%;
            right: 3vw;
          }
          .fa-minus {
            right: 5vw;
          }
          input,
          .select {
            font-size: 1.3vw;
            background-color: white;
            cursor: pointer;
            height: 5.2vw;
            width: 22vw;
            padding-left: 5vw;
          }

          .select-people {
            cursor: auto;
            position: relative;
            .amount-people {
              position: absolute;
              line-height: 5.2vw;
              transform: translate(-50% -50%);
              color: black;
            }
          }
        }
      }
    }
  }
  .table-fixed {
    position: fixed;
    top: 2vw;
    left: 50%;
    height: 8.3vw;
    width: calc(100% - 6vw);
    transform: translateX(-50%);
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: 0;
    background-color: $color-first;
    .available-shadow {
      pointer-events: none;
      background: linear-gradient(180deg, #14353c 0%, rgba(39, 20, 60, 0) 100%);
      height: 10vw;
      top: 8.1vw;
      position: absolute;
      width: 100%;
    }
  }
  .cabin-list {
    width: 1200px;
    @media screen and (max-width: 1200px) {
      margin: auto;
      width: calc(100% - 10vw);
    }
    margin: auto;
    .cabin {
      background-color: white;
      width: 100%;
      display: flex;
      margin-top: 60px;
      display: flex;
      height: 250px;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
      }
      
      .cabin-link {
        cursor: pointer;
        flex-basis: 80%;
        display: flex;
        height: 100%;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          text-align: center;
        }
      }
      .cabin-image {
        width: 25%;
        min-width: 250px;
        display: inline-block;
        vertical-align: top;
        height: 100%;
        @media screen and (max-width: 768px) {
          width: 100%;
          height: 350px;
        }
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .cabin-info {
        padding: 20px;
        width: 75%;
        vertical-align: top;
        display: inline-block;
        color: black;
        height: 100%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        h2 {
          font-size: 34px;
          font-weight: 900;
        }
        p {
          font-size: 18px;
        }
        >div {
          overflow: auto;
          // height: 70%;
        }
        .availDate {
          > div {
            display: flex;
            text-align: center;
            >div {
              padding-right: 20px;
            }
            h2 {
              text-transform: capitalize;
            }
            @media screen and (max-width: 768px) {
              flex-direction: column;
              p,
              h2 {
                margin: 0;
              }
            }
            div:nth-child(2) {
              font-weight: 600;
              font-size: 24px;
              @media screen and (max-width: 768px) {
                margin-top: 20px;
              }
            }
          }
        }
      }
      .select-cabin {
        padding-top: 18px;
        position: relative;
        flex-basis: 20%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          justify-content: center;
          align-items: center;
          width: 100%;
          flex-wrap: wrap;
        }
        .all-btn {
          @media screen and (max-width: 768px) {
            width: 80%;
            margin-bottom: 20px;
          }
          border-radius: 4px;
          > div {
            @media screen and (max-width: 768px) {
              height: 60px;
            }
          }
        }
        .cabin-price {
          font-size: 30px;
          font-weight: 900;
          @media screen and (max-width: 768px) {
            display: flex;
            justify-content: space-around;
            width: 100%;
          }
          span {
            font-size: 12px;
            display: block;
          }
          p {
            margin: 0;
          }
        }
        .check-cabin-availablity {
          margin: auto;
          font-size: 10px;
          cursor: pointer;
        }
        .p-div {
          font-size: 13px;
          margin-bottom: 10px;
        }
        .cabin-price,
        .left,
        .p-div {
          .svg-inline--fa {
            margin-right: 4px;
          }
        }
        .left {
          // position: absolute;
          // top:0;
          margin: auto;
          padding-top: 10px;
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    #packageAvailability {
      .headline {
        margin: auto;
        width: 1200px;
        margin-bottom: 45px;
        margin-top: 160px;

        h1 {
          font-size: 60px !important;
        }
      }

      .table {
        height: 150px;
        margin: auto;
        width: 1200px;

        div {
          margin: 0;
          .input-headline {
            padding-bottom: 14px;
            font-size: 16px;
          }
          .input {
            .th {
              .occupied {
                font-size: 10px;
              }
              .fa-calendar-alt,
              .fa-user-friends {
                font-size: 30px;
                left: 18px;
              }
              .fa-plus,
              .fa-minus {
                font-size: 16px;
                right: 22px;
              }
              .fa-minus {
                right: 44px;
              }
              .add-people,
              .remove-people {
                line-height: 72px;
                width: 36px;
                cursor: pointer;
                .fa-plus,
                .fa-minus {
                  font-size: 16px;
                }
              }
              .add-people {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                right: 1px;
              }
              .remove-people {
                right: 38px;
              }
              .missing {
                color: red !important;
              }
              input,
              .select {
                font-size: 14px;
                width: 264px;
                height: 64px;
                padding-left: 60px;
                border-radius: 4px;
                margin-left: -30px;
              }

              .select-people {
                .amount-people {
                  line-height: 64px;
                  color: black;
                }
              }
            }
            .disabled {
              display: none;
            }
          }
        }
      }
      .table-fixed {
        width: 1200px;
        height: 105px;
        padding-left: 0;
        padding-right: 0;
        .available-shadow {
          height: 110px;
          top: 102px;
          position: absolute;
          width: 100%;
        }
      }
      .cabin-list {
        margin: auto;
        padding-bottom: 60px;
        width: 1065px;
        .cabin {
          .cabin-image {
            img {
              height: 200px;
            }
          }
          margin-top: 24px;
          .cabin-info {
            padding: 18px 18px 0 18px;
            h2 {
              font-size: 32px;
            }
            p {
              font-size: 17px;
            }
            .availDate {
              margin: auto;
              > div {
                p {
                  margin: 0;
                  padding-left: 10px;
                }
                h2 {
                  span {
                    font-size: 12px;
                  }
                }
                div:nth-child(1) {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }

          // .all-btn {
          //   margin-top: 40px;
          // }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #packageAvailability {
    .headline {
      margin-top: 20vw;
      h1 {
        font-size: 9vw;
        line-height: 10vw;
        letter-spacing: 0.5vw;
      }
    }
    .table {
      position: relative;
      height: 125vw;
      div {
        .input-headline {
          div {
            font-size: 3.5vw;
            display: none;
          }
          div:nth-child(1),
          div:nth-child(2) {
            display: block;
            width: 90vw;
          }
          div:nth-child(2) {
            position: absolute;
            top: 27vw;
          }
        }
        .input {
          .th {
            width: 100%;
            margin-bottom: 12vw;
            .fa-calendar-alt,
            .fa-user-friends {
              font-size: 7vw;
              margin-left: 2vw;
            }
            .missing {
              color: red !important;
            }
            .add-people,
            .remove-people {
              line-height: 18vw;
              width: 10vw;
              z-index: 1;
              .fa-plus,
              .fa-minus {
                font-size: 6vw;
                top: 50%;
              }
            }
            .add-people {
              border-top-right-radius: 1vw;
              border-bottom-right-radius: 1vw;
            }
            .remove-people {
              right: 10.5vw;
            }
            .fa-minus {
              right: 10vw;
            }
            input,
            .select {
              padding-left: 15vw;
            }
            .react-bootstrap-daterangepicker-container {
              width: 100%;
              input {
                height: 15vw;
                width: 100%;
                font-size: 4.5vw;
              }
            }
            .select-people {
              font-size: 4.5vw;
              height: 15vw;
              width: 100%;
            }

            .select-people {
              .amount-people {
                line-height: 15vw;
              }
            }
            .book-btn {
              top: 14vw;
              width: calc(100% - 10vw);
              position: fixed;
              background-color: $color-first;
              .btn-shadow {
                background: linear-gradient(180deg, #14353c 0%, rgba(39, 20, 60, 0) 100%);
                height: 20vw;
                top: 14vw;
                position: absolute;
                width: 100%;
              }
            }
            .occupied {
              left: 50%;
              font-size: 3vw;
            }
          }
        }
      }
    }
    .table-fixed {
      position: relative;
      left: 0;
      transform: none;
      top: 0px;
      background-color: transparent;
      width: 100%;
      padding: 0;
    }
    // .cabin-list {
    //   .wrap-cabin-list {
    //     .cabin {
    //       margin-top: 0;
    //       margin-bottom: 16vw;
    //       .cabin-image {
    //         width: 100%;
    //         img {
    //           object-fit: cover;
    //           height: 60vw;
    //         }
    //       }
    //       .cabin-info {
    //         margin-bottom: 4vw;
    //         width: 100%;
    //         text-align: center;
    //         padding-top: 6vw;
    //         h2 {
    //           margin: auto;
    //           font-size: 9vw;
    //           line-height: 10vw;
    //           width: 70%;
    //         }
    //         p {
    //           margin: auto;
    //           font-size: 3.5vw;
    //           line-height: 5.5vw;
    //           width: 70%;
    //         }
    //       }
    // .select-cabin {
    //   margin-bottom: 6vw;
    //   width: 100%;
    //   text-align: center;
    //   .check-cabin-availablity {
    //     width: 70%;
    //     margin: auto;
    //     font-size: 2.5vw;
    //     cursor: pointer;
    //   }
    //   h2 {
    //     margin: auto;
    //     font-size: 9vw;
    //     line-height: 10vw;
    //     width: 70%;
    //   }
    //   .p-div {
    //     margin: auto;
    //     font-size: 3.5vw;
    //     line-height: 5.5vw;
    //     width: 70%;
    //     .svg-inline--fa {
    //       margin-right: 1vw;
    //     }
    //   }
    // }
    // }
    // }
    // }
  }
  .daterangepicker.single {
    width: calc(100% - 10vw) !important;
    .drp-calendar {
      width: 100%;
      max-width: 88vw;
    }
  }
}
