@import "src/colorVariable.scss";
content {
  .carousel {
    margin-top: 5vw;
    width: 100%;
    .react-images__footer {
      display: none;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 50vw;
    }
  }
  .about {
    width: 100%;
    .daterangepicker {
      right: 12vw;
      top: 63vw;
    }
    width: 100%;
    padding-bottom: 6.2vw;
    .about-content1 {
      margin-top: 6.2vw;
      margin-left: 6.2vw;
      vertical-align: top;
      width: calc(65% - 6.2vw);
      display: inline-block;
      color: #b7b7b7;
      h1 {
        font-weight: 900;
        color: #ffffff;
        line-height: 5vw;
        margin-top: 0;
        padding-bottom: 4vw;
        font-size: 4.8vw;
      }
      p {
        padding-right: 3.1vw;
        white-space: pre-line;

        font-size: 1.55vw;
        font-weight: 400;
        line-height: 1.9vw;
        /* Text style for "has 2 bedr" */
        span {
          color: $color-second;
        }
      }
      .go-button {
        text-align: center;
        cursor: pointer;
        color: #ffffff;
        height: 4.65vw;
        margin: 2.5vw 5vw 2.5vw 0;
        display: inline-block;
        vertical-align: top;
        border: 0;
        border-radius: 0;
        border-radius: 0.3vw;
        box-sizing: border-box;
        background-color: $color-second;
        font-size: 1.6vw;
        font-weight: 900;
      }
      .go-button.camping {
        background-color: $color-select-button;
        border: 1px solid white;
      }
    }
    .about-content2 {
      margin-top: 6.2vw;
      width: calc(35% - 1.2vw);
      display: inline-block;
      vertical-align: top;
      position: relative;

      p:nth-child(1) {
        color: #ffffff;
      }
      .comment {
        display: none;
        padding-top: 4vw;
        padding-bottom: 4vw;
        ul {
          padding-top: 1vw;
          padding-left: 1.6vw;
          color: white;
          li {
            font-size: 1.6vw;
            list-style-type: none;
            strong {
              font-weight: 400;
            }
          }
        }
        p {
          margin: auto;
          font-size: 1.6vw;

          font-weight: 400;
          width: calc(100% - calc(3.8vw - 3px));

          line-height: 2.3vw;
          color: white;
        }
        p:nth-child(2) {
          padding-top: 1.6vw;
          line-height: 2.2vw;
          font-weight: 600;
          font-size: 1.3vw;
          text-transform: uppercase;
          color: $color-second;
        }

        img {
          z-index: -1;
          position: absolute;
          width: 100%;
        }
      }
      .available-headline {
        color: #fab84c;
        font-size: 1.1vw;
        margin-bottom: -5px;
      }
      .check-availabilty {
        position: relative;
        .fa-calendar-alt {
          padding-left: 0.8vw;
          padding-right: 0.8vw;
          top: 1vw;
          position: absolute;
          color: #000000;
          font-size: 4vw;
        }
        .calendar {
          font-size: 1.3vw;
          font-weight: 500;
          height: 4.65vw;
          width: calc(22vw - 2px);
          padding-left: 4vw;
          margin: 0.6vw 0;
          display: inline-block;
          vertical-align: top;
          border: 0;
          border-radius: 0;
          border-top-left-radius: 0.3vw;
          border-bottom-left-radius: 0.3vw;
          box-sizing: border-box;
        }
        .go-button {
          text-align: center;
          cursor: pointer;
          color: #ffffff;
          height: 4.65vw;
          width: 20%;
          margin: 0.6vw 0;
          display: inline-block;
          vertical-align: top;
          border: 0;
          border-radius: 0;
          border-top-right-radius: 0.3vw;
          border-bottom-right-radius: 0.3vw;
          box-sizing: border-box;
          background-color: $color-second;
          font-size: 1.6vw;
          font-weight: 900;
        }
        .hidden-button {
          visibility: hidden;
        }
        .occupied {
          position: absolute;
          color: red;
          font-size: 1vw;
        }
      }
      p {
        white-space: pre-line;
        font-size: 1.2vw;
        font-weight: 900;
        line-height: 1.8vw;
      }
      p:nth-child(4) {
        font-weight: 200;
      }
    }
  }
  .wrap-packages {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: auto;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 4.3vw);
    }
    

    .package-boks {
      margin-top: 20px;
      display: flex;
      background-color: white;
      height: 250px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        height: unset;
      }
      img {
        object-fit: cover;
        width: 25%;
        @media screen and (max-width: 768px) {
          width: 100%;
          height: 60vw;
        }
      }
      .border-wrap {
        display: flex;
        width: 75%;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }
        > div {
          padding: 20px;
          flex-basis: 70%;
          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
          }
          .package-headline {
            font-size: 34px;
            @media screen and (max-width: 768px) {
              font-size: 6vw;
            }
            font-weight: 900;
          }
          .package-text {
            flex-basis: 20%;
            p {
              @media screen and (max-width: 768px) {
                font-size: 3.5vw;
              }
              font-weight: unset;
            }
          }
        }
        a {
          flex-basis: 30%;
          padding-right: 20px;
          @media screen and (max-width: 768px) {
            width: 70%;
            padding: 0;
            margin-bottom: 10vw;
          }
          .package-button {
            @media screen and (max-width: 768px) {
              height: 12vw;
              font-size: 4vw;
            }
            font-weight: 600;
            border-radius: 4px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0;
            color: #ffffff;
            background-color: #fab84c;
          }
        }
      }
    }
  }
  .cabins {
    padding: 4.3vw 0 4.3vw 4.3vw;
    margin: auto;
    color: #4a4a4a;
    background-color: #eae9e9;
    .wrap-text-cabins {
      h1 {
        margin-bottom: 0;
        font-weight: 900;
        font-size: 4.8vw;
      }
      .wrap-cabins {
        white-space: nowrap;
        width: 100%;
        margin: auto;
        overflow-y: auto;
        p {
          margin-bottom: 6vw;
          margin-top: 0;
          white-space: pre-line;
          font-size: 1.9vw;
          font-weight: 900;
          line-height: 2vw;
        }
        .cabin-boks {
          background-color: #ffffff;
          text-align: center;
          width: calc(25% - 2.9vw);
          display: inline-block;
          vertical-align: top;
          margin-right: 3.8vw;
          margin-bottom: 3.8vw;
          position: relative;
          .on-request {
            position: absolute;
            background-color: rgba($color: $color-first, $alpha: 0.4);
            border-bottom-right-radius: 4px;
            display: flex;
            padding: 0 20px;
            color: white;
            p {
              font-size: unset;
              margin: unset;
              padding: 8px;
              line-height: unset;
            }
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 15.8vw;
          }
          .cabin-headline {
            font-weight: 900;
            font-size: 2vw;
            padding: 1.6vw;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .cabin-text {
            p {
              font-size: 1.45vw;
              line-height: 2vw;
              margin: auto;
              font-weight: 400;
            }

            padding: 0 2vw;
            margin-bottom: 2vw;
            height: 4vw;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .cabin-button {
            color: #ffffff;
            background-color: $color-second;
            margin: 2vw;
            margin-top: 0;
            text-align: center;
            div {
              width: 16vw;
              text-align: center;
              height: 4vw;
              font-size: 1.3vw;
              font-weight: 600;
              display: table-cell;
              vertical-align: middle;
            }
          }
          .camping-button {
            background-color: $color-select-button;
          }
          .cabin-button-sold-out {
            background-color: #dcdcdc;
          }
        }
        // .cabin-boks:nth-child(4n) {
        //   margin-right: 0;
        // }
      }
    }
  }

  .cabins:nth-child(even) {
    background-color: #ffffff;
    .border-wrap {
      border: 1px solid #d3d3d3;
      // border-top: 0;
    }
  }
  .wild-life {
    width: 100%;
    min-height: 200px;
    padding-bottom: 2.2vw;
    color: #ffffff;
    .wrap-wild-life {
      margin: auto;
      .react-images__footer {
        display: none;
      }
      .wild-life-text {
        margin-top: 6.2vw;
        margin-left: 6.2vw;
        vertical-align: top;
        width: calc(50% - 6.2vw);
        display: inline-block;
        h1 {
          margin-bottom: 5vw;
          font-weight: 900;
          padding-top: 0;
          margin-top: 0;
          font-size: 4.8vw;
        }
        p {
          padding-right: 3.1vw;
          white-space: pre-line;
          color: #b7b7b7;
          font-size: 1.55vw;
          font-weight: 400;
          line-height: 2vw;
          span {
            color: $color-second;
          }
        }
      }
      .wild-life-image {
        margin-top: 6.2vw;

        vertical-align: top;
        width: calc(50% - 6.2vw);
        display: inline-block;
        img {
          object-fit: cover;
          width: 100%;
          height: 30vw;
        }
      }
    }
  }
  .contact {
    background-color: #eae9e9;
    color: #4a4a4a;
    width: 100%;
    padding-bottom: 6.2vw;
    padding-top: 0.1px;

    .contact-form {
      margin-top: 6.2vw;
      margin-left: 6.2vw;
      vertical-align: top;
      width: calc(100% - 12.4vw);
      h1 {
        margin: 0;
        font-weight: 900;
        font-size: 4.8vw;
      }
      p {
        font-size: 1.3vw;
        font-weight: 400;
        margin-bottom: 0.7vw;
        margin-top: 3vw;
      }
      .contact-name {
        width: 100%;

        div {
          position: relative;
          display: inline-block;
          width: 41.5vw;
          input {
            font-size: 1.3vw;
            width: 100%;
          }
          .warning-message {
            font-size: 1.3vw;
            position: absolute;
            left: 0;
            margin-top: 3.5vw;
            color: red;
          }
        }

        div:nth-child(2) {
          float: right;
        }
      }
      .email {
        width: 100%;
        position: relative;
        input {
          font-size: 1.3vw;
          width: 100%;
        }
        .warning-message {
          font-size: 1.3vw;
          position: absolute;
          left: 0;
          margin-top: 3.5vw;
          color: red;
        }
      }
      .comment {
        width: 100%;
        textarea {
          font-family: inherit;

          resize: none;
          font-size: 1.3vw;
          height: 14vw;
          padding: 1.2vw;
          border: 1px solid #c6c6c5;
          border-radius: 0.3vw;
          border-radius: 0.3vw;
          box-sizing: border-box;
          width: 100%;
          outline: none;
        }
        div {
          position: relative;
          .warning-message {
            font-size: 1.3vw;
            position: absolute;
            left: 0;
            bottom: -1vw;
            color: red;
          }
        }
      }
      .send-contact {
        cursor: pointer;
        color: #ffffff;
        margin-top: 3vw;
        text-align: center;
        height: 4vw;
        div {
          float: right;
          .send-button {
            border-radius: 0.3vw;
            border-radius: 0.3vw;
            float: none;
            background-color: $color-second;
            width: 16vw;
            text-align: center;
            height: 4vw;
            font-size: 1.3vw;
            font-weight: 600;
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      position: relative;
      .sent-message {
        font-size: 1.3vw;
        position: absolute;
        right: 0;
        color: green;
      }
    }
  }
  .testimonials {
    color: #ffffff;
    width: 100%;
    padding-bottom: 6.2vw;
    padding-top: 0.1px;
    text-align: center;
    .wrap-testimonials-text {
      padding-top: 6.2vw;
      margin: auto;
      width: 61.9%;
      h1 {
        font-weight: 900;
        font-size: 3vw;
        .thin-text {
          font-weight: 300;
        }
      }
      h2 {
        font-weight: 400;
        font-size: 2vw;
      }
      .testimonials-line {
        margin: auto;
        width: 5.5vw;
        height: 0.25vw;
        background-color: $color-second;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  content {
    .carousel {
      margin-top: 60px;
      img {
        height: 600px;
      }
    }
    .about {
      text-align: center;
      padding-bottom: 100px;
      .about-content1 {
        margin-top: 55px;
        margin-left: 0;
        text-align: left;
        width: 694px;
        h1 {
          line-height: 60px;
          font-size: 60px;
          margin-left: 0px;
          margin-right: 30px;
          padding-bottom: 30px;
        }
        p {
          font-size: 17px;
          line-height: 22px;
        }
        .go-button {
          height: 55px;
          margin: 30px 60px 30px 0;
          padding: 0 20px;
          border-radius: 4px;
          font-size: 22px;
        }
      }
      .about-content2 {
        margin-top: 55px;
        text-align: left;
        width: 340px;
        h1 {
          line-height: 60px;
          font-size: 60px;
          margin-left: 0px;
        }

        p {
          font-size: 16px !important;
          line-height: 22px !important;
        }
        .comment {
          padding-bottom: 48px;
          padding-top: 48px;
          ul {
            padding-top: 14px;
            padding-left: 20px;
            li {
              font-size: 17px !important;
            }
          }
          p {
            width: calc(100% - 42px);
          }
          p:nth-child(2) {
            padding-top: 24px;
          }
          p:nth-child(3) {
            letter-spacing: 0.4px;
          }
          p:nth-child(4) {
            font-weight: 400;
            font-size: 14px !important;
          }
        }
        .available-headline {
          font-size: 14px;
        }
        .check-availabilty {
          input::placeholder {
            color: rgba($color: #000000, $alpha: 0.4);
          }
          .fa-calendar-alt {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 50px;
            top: 10px;
            left: 5px;
          }
          .go-button {
            height: 55px;
            margin: 8px 0;
            width: 67px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            font-size: 22px;
          }
          .occupied {
            font-size: 14px;
          }
          .calendar {
            height: 55px;
            font-size: 16px;
            width: 272px;
            padding-left: 50px;
            padding-right: 10px;
            margin: 8px 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
    .cabins {
      padding: 60px 0 60px 60px;
      .wrap-text-cabins {
        margin-top: 60px;
        width: 100%;
        .cabins-text {
          width: 1200px;
          margin: auto;
          text-align: left;
          h1 {
            line-height: 60px;
            font-size: 60px;
          }
          p {
            margin-bottom: 40px;
            font-size: 20px;
          }
        }

        .wrap-cabins {
          white-space: nowrap;
          margin: auto;
          overflow-y: auto;
          width: calc(100% - (50% - 600px));
          margin-left: calc(50% - 600px);
          .cabin-boks {
            width: 230px;
            margin-right: 40px;
            margin-bottom: 40px;
            img {
              height: 190px;
            }
            .cabin-headline {
              font-size: 24px;
              padding: 20px;
            }
            .cabin-text {
              p {
                font-size: 18px;
                line-height: 24px;
                margin: auto;
                font-weight: 400;
              }
              padding: 0 22px;
              margin-bottom: unset;
              height: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .cabin-button {
              margin: 22px;
              margin-top: 0;
              div {
                width: 190px;
                height: 46px;
                font-size: 16px;
              }
            }
          }
          // .cabin-boks:nth-child(4n) {
          //   margin-right: 0;
          // }
        }
      }
    }
    .wild-life {
      text-align: center;
      padding-bottom: 20px;
      .wrap-wild-life {
        width: 1040px;
        .wild-life-text {
          text-align: left;
          margin-top: 60px;
          margin-left: 0;
          width: 520px;
          h1 {
            line-height: 60px;
            font-size: 60px;
            margin-left: 0px;
            margin-bottom: 40px;
            padding-right: 40px;
          }
          p {
            line-height: 22px;
            padding-right: 20px;
            font-size: 17px;
          }
        }
        .wild-life-image {
          margin-top: 60px;
          width: 520px;
          img {
            height: 360px;
          }
        }
      }
    }
    .contact {
      padding-bottom: 60px;
      .contact-form {
        margin: auto;
        margin-top: 60px;
        width: 1040px;
        h1 {
          line-height: 60px;
          font-size: 60px;
          margin-left: 0px;

          padding-right: 40px;
        }
        .contact-name {
          div {
            width: 48%;
            input {
              font-size: 16px;
              height: 46px;
              padding-left: 14px;
              padding-right: 14px;
              border-radius: 4px;
              border-radius: 4px;
            }
            .warning-message {
              margin-top: 46px;
              font-size: 16px;
            }
          }
        }
        .email {
          input {
            font-size: 16px;
            height: 46px;
            padding-left: 14px;
            padding-right: 14px;
            border-radius: 4px;
            border-radius: 4px;
          }
          .warning-message {
            margin-top: 46px;
            font-size: 16px;
          }
        }
        p {
          font-size: 16px;
          margin-bottom: 8px;
          margin-top: 35px;
        }
        .comment {
          textarea {
            font-size: 16px;
            height: 170px;
            padding: 14px;
            border-radius: 4px;
            border-radius: 4px;
          }
          div {
            .warning-message {
              bottom: -16px;
              font-size: 16px;
            }
          }
        }
        .send-contact {
          margin-top: 35px;
          height: 50px;
          div {
            .send-button {
              border-radius: 4px;
              width: 190px;
              height: 50px;
              font-size: 16px;
            }
          }
        }
        position: relative;
        .sent-message {
          font-size: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  content {
    .carousel {
      img {
        height: 110vw;
      }
    }
    .about {
      display: flex;
      flex-direction: column;
      .about-content1 {
        margin-top: 12.2vw;
        margin-left: auto;
        margin-right: auto;
        order: 2;
        width: 90%;
        h1 {
          font-size: 10vw;
          line-height: 10vw;
          letter-spacing: 0.5vw;
          padding-bottom: 8vw;
        }
        p {
          font-size: 3.5vw;
          line-height: 5vw;
        }
        .go-button {
          padding: 0 3vw;
          height: 13vw;
          margin-top: 7vw;
          font-size: 5vw;
          border-radius: 1vw;
        }
      }
      .about-content2 {
        order: 1;
        width: 100%;
        margin-top: 10.2vw;
        p {
          font-size: 3.2vw;
          margin-left: 5vw;
        }
        .available-headline {
          font-size: 2.8vw;
          margin-left: 5vw;
          margin-top: 6px;
          margin-bottom: -2px;
        }
        .check-availabilty {
          margin: auto;
          text-align: center;
          display: flex;
          justify-content: center;
          .calendar {
            height: 15vw;
            width: 75vw;
            font-size: 4vw;
            padding-left: 16vw;
            border-top-left-radius: 1vw;
            border-bottom-left-radius: 1vw;
          }
          .go-button {
            width: 15vw;
            height: 15vw;
            font-size: 5vw;
            border-top-right-radius: 1vw;
            border-bottom-right-radius: 1vw;
          }
          .occupied {
            font-size: 14px;
          }
          .fa-calendar-alt {
            padding-left: 0px;
            padding-right: 0px;
            left: 9vw;
            top:50%;
            transform: translateY(-50%);
            font-size: 8vw;
            max-height: 40px;
          }
        }
        .comment {
          display: none;
        }
      }
    }
    .cabins {
      padding: 0;
      padding-top: 12vw;
      .wrap-text-cabins {
        width: 90vw;
        margin: auto;
        h1 {
          font-size: 9vw;
          line-height: 10vw;
        }
        p {
          font-size: 3.5vw;
          line-height: 5.5vw;
          margin-left: 1vw;
        }
        .wrap-cabins {
          white-space: unset;
          overflow: unset;
          .cabin-boks {
            width: 90vw;
            margin-bottom: 5vw;
            margin-right: 0;
            img {
              height: 60vw;
            }
            .cabin-headline {
              margin: auto;
              padding-top: 3vw;
              width: 70%;
              font-size: 6vw;
            }
            .cabin-text {
              p {
                font-size: 3.5vw;
                line-height: 3.5vw;
                margin: auto;
                font-weight: 400;
              }
              height: 11vw;
              margin: auto;
              width: 70%;
            }
            .cabin-button {
              width: 70%;
              margin: auto;
              margin-bottom: 10vw;
              margin-top: 5vw;
              height: 12vw;
              div {
                width: 100vw;
                height: 12vw;
                font-size: 4vw;
              }
            }
          }
        }
      }
    }
    .wild-life {
      .wrap-wild-life {
        .wild-life-text {
          margin-top: 12vw;
          width: calc(100% - 12.4vw);
          h1 {
            font-size: 9vw;
            line-height: 10vw;
          }
          p {
            font-size: 3.5vw;
            line-height: 5.5vw;
          }
        }
        .wild-life-image {
          display: none;
        }
      }
    }

    .contact {
      .contact-form {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12vw;
        h1 {
          margin-left: 5vw;
          font-size: 9vw;
          line-height: 10vw;
        }

        .contact-name {
          div {
            margin: auto;
            display: block;
            width: 90vw;
            float: none !important;
            p {
              margin-bottom: 2vw;
              margin-top: 5vw;
              font-size: 3.5vw;
            }
            input {
              padding-left: 3vw;
              padding-right: 3vw;
              font-size: 3.5vw;
              height: 14vw;
              border-radius: 1vw;
            }
            .warning-message {
              margin-top: 14vw;
              font-size: 2.5vw;
            }
          }
        }
        .email,
        .comment {
          margin: auto;
          display: block;
          width: 90vw;
          p {
            margin-bottom: 2vw;
            margin-top: 5vw;
            font-size: 3.5vw;
          }
          input,
          textarea {
            padding-left: 3vw;
            padding-right: 3vw;
            font-size: 3.5vw;
            height: 14vw;
            border-radius: 1vw;
          }
          .warning-message {
            margin-top: 14vw;
            font-size: 2.5vw;
          }
          textarea {
            padding: 3.2vw;
            height: 40vw;
          }
          div {
            .warning-message {
              bottom: -1.5vw;
              font-size: 2.5vw;
            }
          }
        }
        .send-contact {
          margin-top: 6vw;
          margin-bottom: 6vw;
          height: 14vw;
          div {
            float: none;
            margin: auto;
            width: 90vw;

            .send-button {
              margin: auto;
              width: 90vw;
              height: 14vw;
              font-size: 3.5vw;
              border-radius: 1vw;
            }
          }
        }
        position: relative;
        .sent-message {
          font-size: 3.5vw;
          right: 5vw;
        }
      }
    }
  }
  .daterangepicker {
    left: 5vw;
    width: calc(100% - 10vw) !important;
    .drp-calendar {
      max-width: 88vw !important;
    }
  }
}
