@import "src/colorVariable.scss";
#availability {
  .headline {
    margin-top: 10vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5vw;
    h1 {
      font-weight: 900;
      color: white;
      font-size: 4.8vw;
    }
  }

  .table {
    height: 10vw;
    margin: auto;
    width: calc(100% - 10vw);
    margin-left: 5vw;
    color: $color-second;
    div {
      .input-headline {
        vertical-align: bottom;
        padding-bottom: 1.2vw;
        font-size: 1.3vw;
        border: none;
        div {
          display: inline-block;
          width: 25%;
        }
      }
      .input {
        vertical-align: bottom;
        border: none;
        position: relative;
        .th {
          position: relative;
          display: inline-block;
          width: 25%;
          vertical-align: top;

          .occupied {
            left: 45%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            position: absolute;
            color: red;
            font-size: 0.7vw;
          }

          .fa-calendar-alt,
          .fa-user-friends,
          .fa-sort-down {
            pointer-events: none;
            color: black;
            font-size: 2.2vw;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            z-index: 1;
            left: 1.5vw;
          }
          .add-people,
          .remove-people {
            line-height: 5.3vw;
            height: 100%;
            width: 2.5vw;
            text-align: center;
            position: absolute;
            z-index: 1;
            background-color: #dcdcdc;
            right: 0;
            cursor: pointer;
            .fa-plus,
            .fa-minus {
              font-size: 1.3vw;
              color: white;
            }
          }
          .add-people {
            border-top-right-radius: 0.3vw;
            border-bottom-right-radius: 0.3vw;
            right: 0.1vw;
          }
          .remove-people {
            right: 2.7vw;
          }
          .missing {
            color: red !important;
          }
          .fa-plus,
          .fa-minus {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            cursor: pointer;
            pointer-events: fill;
            font-size: 1.4vw;
            left: auto;
            top: 20%;
            right: 3vw;
          }
          .fa-minus {
            right: 5vw;
          }
          input,
          .select {
            font-size: 1.3vw;
            background-color: white;
            cursor: pointer;
            height: 5.2vw;
            width: 22vw;
            padding-left: 5vw;
          }
          .all-btn {
            float: right;
            cursor: pointer;
            height: 5.2vw;
            width: calc(100% - 0.4vw);
            position: relative;
            div {
              width: 20vw;
              position: absolute;
              top: 70%;
              transform: translateY(-50%);
            }
          }
          .select-people {
            cursor: auto;
            position: relative;
            .amount-people {
              position: absolute;
              line-height: 5.2vw;
              transform: translate(-50% -50%);
              color: black;
            }
          }
        }
      }
    }
  }
  .table-fixed {
    position: fixed;
    top: 2vw;
    left: 50%;
    height: 8.3vw;
    width: calc(100% - 6vw);
    transform: translateX(-50%);
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: 0;
    background-color: $color-first;
    .available-shadow {
      pointer-events: none;
      background: linear-gradient(180deg, #14353c 0%, rgba(39, 20, 60, 0) 100%);
      height: 10vw;
      top: 8.1vw;
      position: absolute;
      width: 100%;
    }
  }
  .cabin-list {
    margin-left: 5vw;
    margin-right: 5vw;
    padding-bottom: 5vw;
    .cabin {
      background-color: white;
      width: 100%;
      margin-top: 2vw;
      display: flex;
      @media only screen and (max-width: 768px) {
        padding-right: unset;
        flex-direction: column;
      }
      .cabin-link {
        display: flex;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }
        cursor: pointer;

      }
      .cabin-image {
        width: 25%;
        display: inline-block;
        vertical-align: top;
        min-width: 250px;
        img {
          object-fit: cover;
          height: 15vw;
          width: 100%;
          height: 100%;
        }
        .on-request {
          position: absolute;
          background-color: rgba($color: $color-first, $alpha: 0.4);
          border-bottom-right-radius: 4px;
          display: flex;
          padding: 0 20px;
          color: white;
          p{
            font-size: unset;
            margin: 8px 0;
          }
        }
      }
      .cabin-info {
        padding: 1.5vw;
        width: 100%;
        vertical-align: top;
        display: inline-block;
        color: black;
        h2 {
          font-size: 2.7vw;
          font-weight: 900;
        }
        p {
          font-size: 1.4vw;
        }
      }
      .select-cabin {
        padding-top: 1.5vw;
        width: 25%;
        vertical-align: top;
        display: inline-block;
        .cabin-price {
          font-size: 2.7vw;
          font-weight: 900;
        }

        .p-div {
          font-size: 1.2vw;

          .svg-inline--fa {
            vertical-align: 0px;
            margin-right: 1vw;
          }
          .react-bootstrap-daterangepicker-container {
            cursor: pointer;
          }
        }
        .cabin-price,
        .p-div {
          width: 16vw;
          margin: auto;
          .svg-inline--fa {
            vertical-align: 0px;
            margin-right: 0.3vw;
          }
        }
        .check-cabin-availablity {
          width: 16vw;
          margin: auto;
          font-size: 1vw;
          cursor: pointer;
        }
        .all-btn {
          margin: auto;
          margin-top: 2vw;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  #availability {
    .headline {
      margin: auto;
      width: 1080px;
      margin-bottom: 45px;
      margin-top: 160px;

      h1 {
        font-size: 60px !important;
      }
    }
    .table {
      height: 150px;
      margin: auto;
      width: 1080px;

      div {
        margin: 0;
        .input-headline {
          padding-bottom: 14px;
          font-size: 16px;
        }
        .input {
          .th {
            display: inline-block;
            width: 25%;
            .occupied {
              font-size: 10px;
            }
            .fa-calendar-alt,
            .fa-user-friends {
              font-size: 30px;
              left: 18px;
            }
            .fa-plus,
            .fa-minus {
              font-size: 16px;
              right: 22px;
            }
            .fa-minus {
              right: 44px;
            }
            .add-people,
            .remove-people {
              line-height: 72px;
              width: 36px;
              cursor: pointer;
              .fa-plus,
              .fa-minus {
                font-size: 16px;
              }
            }
            .add-people {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              right: 1px;
            }
            .remove-people {
              right: 38px;
            }
            .missing {
              color: red !important;
            }
            input,
            .select {
              font-size: 14px;
              width: 264px;
              height: 64px;
              padding-left: 60px;
              border-radius: 4px;
            }
            .all-btn {
              height: 64px;
              width: 264px;
              div {
                font-size: 16px;
                width: 264px;
              }
            }
            .select-people {
              .amount-people {
                line-height: 64px;
                color: black;
              }
            }
          }
        }
      }
    }
    .table-fixed {
      width: 1080px;
      height: 105px;
      padding-left: 0;
      padding-right: 0;
      top: 60px;
      .available-shadow {
        height: 110px;
        top: 102px;
        position: absolute;
        width: 100%;
      }
    }
    .cabin-list {
      margin: auto;
      padding-bottom: 60px;
      width: 1065px;
      .cabin {
        .cabin-image {
          img {
            height: 200px;
          }
        }
        margin-top: 24px;
        .cabin-info {
          padding: 18px;
          h2 {
            font-size: 32px;
          }
          p {
            font-size: 17px;
          }
        }
        .select-cabin {
          padding-top: 18px;
          .cabin-price {
            font-size: 32px;
          }
          .check-cabin-availablity {
            width: 190px;
            margin: auto;
            font-size: 10px;
            cursor: pointer;
          }
          .p-div {
            font-size: 14px;
          }
          .cabin-price,
          .p-div {
            .svg-inline--fa {
              margin-right: 4px;
            }
            width: 190px;
          }
          .all-btn {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #availability {
    .headline {
      margin-top: 20vw;
      h1 {
        font-size: 9vw;
        line-height: 10vw;
        letter-spacing: 0.5vw;
      }
    }
    .table {
      position: relative;
      height: 125vw;
      div {
        .input-headline {
          div {
            font-size: 3.5vw;
            display: none;
          }
          div:nth-child(1),
          div:nth-child(2) {
            display: block;
            width: 90vw;
          }
          div:nth-child(2) {
            position: absolute;
            top: 27vw;
          }
        }
        .input {
          .th {
            width: 100%;
            margin-bottom: 12vw;
            .fa-calendar-alt,
            .fa-user-friends {
              font-size: 7vw;
              margin-left: 2vw;
            }
            .missing {
              color: red !important;
            }
            .add-people,
            .remove-people {
              line-height: 18vw;
              width: 10vw;
              z-index: 1;
              .fa-plus,
              .fa-minus {
                font-size: 6vw;
                top: 50%;
              }
            }
            .add-people {
              border-top-right-radius: 1vw;
              border-bottom-right-radius: 1vw;
            }
            .remove-people {
              right: 10.5vw;
            }
            .fa-minus {
              right: 10vw;
            }
            input,
            .select {
              padding-left: 15vw;
            }
            .react-bootstrap-daterangepicker-container {
              width: 100%;
              input {
                height: 15vw;
                width: 100%;
                font-size: 4.5vw;
              }
            }
            .select-people {
              font-size: 4.5vw;
              height: 15vw;
              width: 100%;
            }
            .all-btn {
              height: 15vw;
              width: 100%;
              div {
                font-size: 5vw;
                top: 75%;
                width: 100%;
              }
            }
            .select-people {
              .amount-people {
                line-height: 15vw;
              }
            }
            .book-btn {
              top: 14vw;
              width: calc(100% - 10vw);
              position: fixed;
              background-color: $color-first;
              .btn-shadow {
                background: linear-gradient(
                  180deg,
                  #14353c 0%,
                  rgba(39, 20, 60, 0) 100%
                );
                height: 20vw;
                top: 14vw;
                position: absolute;
                width: 100%;
              }
            }
            .occupied {
              left: 50%;
              font-size: 3vw;
            }
          }
        }
      }
    }
    .table-fixed {
      position: relative;
      left: 0;
      transform: none;
      top: 0px;
      background-color: transparent;
      width: 100%;
      padding: 0;
    }
    .cabin-list {
      .wrap-cabin-list {
        .cabin {
          margin-top: 0;
          margin-bottom: 16vw;
          .cabin-image {
            width: 100%;
            img {
              object-fit: cover;
              height: 60vw;
            }
          }
          .cabin-info {
            margin-bottom: 4vw;
            width: 100%;
            text-align: center;
            padding-top: 6vw;
            h2 {
              margin: auto;
              font-size: 9vw;
              line-height: 10vw;
              width: 70%;
            }
            p {
              margin: auto;
              font-size: 3.5vw;
              line-height: 5.5vw;
              width: 70%;
            }
          }
          .select-cabin {
            margin-bottom: 6vw;
            width: 100%;
            text-align: center;
            .check-cabin-availablity {
              width: 70%;
              margin: auto;
              font-size: 2.5vw;
              cursor: pointer;
            }
            h2 {
              margin: auto;
              font-size: 9vw;
              line-height: 10vw;
              width: 70%;
            }
            .p-div {
              margin: auto;
              font-size: 3.5vw;
              line-height: 5.5vw;
              width: 70%;
              .svg-inline--fa {
                margin-right: 1vw;
              }
            }
            .all-btn {
              width: 70%;
              margin-top: 6vw;
              div {
                font-size: 5vw;
              }
            }
          }
        }
      }
    }
  }
  .daterangepicker.single {
    width: calc(100% - 10vw) !important;
  }
}
